import { render, staticRenderFns } from "./SettingsCompanyCard.vue?vue&type=template&id=02ca88d6&scoped=true&"
import script from "./SettingsCompanyCard.vue?vue&type=script&lang=js&"
export * from "./SettingsCompanyCard.vue?vue&type=script&lang=js&"
import style0 from "./SettingsCompanyCard.vue?vue&type=style&index=0&id=02ca88d6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02ca88d6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VAlert,VBtn,VCard,VCardActions,VCardTitle,VCol,VForm,VIcon,VRow,VSlider,VSubheader,VSwitch})
